import React, { FC } from 'react'
import styles from './orderItem.module.scss'
import { HasId } from '../../types/common.types'
import useTheme from '../../hooks/useAppColor'

type OrderItemProps = {
    rightTopText: string,
    leftBottomText: string,
    rightBottomText: string
    codeText?: string
} & HasId
export const OrderItem: FC<OrderItemProps> = ({ rightBottomText, rightTopText, leftBottomText, id, codeText }) => {
    const getTheme = useTheme()
    return (
        <div className={`f-column gap-10 ${styles.orderItem} border-bottom`}>
            <div className="f-row-betw">
                <div className="d-f al-center gap-10">
                    <p className={`fz-m ${getTheme("c-dark", "c-lg")} fw-5`}>Заказ №</p>
                    <div className={`grayInfo fz-s ${getTheme("", "bg-dark c-white")}`}>
                        {codeText}
                    </div>
                </div>
                <p className={`fz-m ${getTheme("c-dark", "c-lg")}  fw-6`}>{rightTopText}</p>
            </div>
            <div className="f-row-betw">
                <p className='fz-s c-lg'>{leftBottomText}</p>
                <p className='fz-s c-lg'>{rightBottomText}</p>
            </div>
        </div>
    )
}

import { useAppSelector } from "../app/hooks";

const useTheme = () => {
    const { theme } = useAppSelector(state => state.settings)
    const getTheme = (lightStyle: string, darkStyle: string): string => {
        return theme.dark ? darkStyle : lightStyle
    }
    return getTheme
};

export default useTheme;
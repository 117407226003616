import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { getChartBonusesData, BonusesSlice } from "../bonuses/bonusesSlice"
import { getFromStorage } from "../../utils/LocalStorageExplorer"


const initialState = {
    theme: {
        dark: getFromStorage("theme") || false
    }
}

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        handleTheme: (state,) => {
            state.theme.dark = !state.theme.dark
        }
    },

})

export const {
    handleTheme
} = settingsSlice.actions


export const settingsReducer = settingsSlice.reducer
import React, { FC } from 'react'
import { AddIcon, AvatarIcon, CheckedCircle, RemoveIcon } from '../../../icons'
import { CartItemType } from '../../../features/cart/cartSlice'
import useTheme from '../../../hooks/useAppColor'

type CartItemProps = {
    item: CartItemType,
    removeItem?: () => void
}

export const CartItem: FC<CartItemProps> = ({ item, removeItem }) => {
    const getTheme = useTheme()
    return (
        <div className="analysis-item pd-10-0 border-bottom f-row-betw gap-15">
            <div className="f-column f-1">
                <p className={`${getTheme("c-dark", "c-lg")} fz-m fw-4`}>{item.name}</p>
                <p className={`${getTheme("c-dark", "c-white")} fz-m fw-7`}>{item.cost} ₽</p>
            </div>
            <div onClick={removeItem} className="f-c-col">
                <RemoveIcon />
            </div>
        </div>
    )
}

import React from 'react';
import { Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import PublicRoute from "./PublicRoute";
import AuthRoute from "./AuthRoute";
import NonAuthRoute from "./NonAuthRoute";
import { Orders } from '../pages/Orders';
import { WelcomePatients } from '../pages/Welcome/WelcomePatients';
import AccessRoute from './AccessRoute';
import HasProfileRoute from './HasProfileRoute';
import NonAccessRoute from './NonAccessRoute';
import { ProfileCreate } from '../pages/ProfileCreate';
import { DocumentAccept } from '../pages/DocumentAccept';
import { InvitingForm } from '../pages/InvitingForm';
import { InvitingPhone } from '../pages/InvitingPhone';
import { PatientPinned } from '../pages/Informational/PatientPinned';
import { PatientInvited } from '../pages/Informational/PatientInvited';
import { OrderPatient } from '../pages/OrderPatient';
import { OrderCategory } from '../pages/OrderCategory';
import { OrderAnalysis } from '../pages/OrderAnalysis';
import { OrderCart } from '../pages/Cart';

const AppRoutes = () => {
    return (
        <Routes>
            {
                routes.has_profile.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <HasProfileRoute Component={Component} />
                        }
                    />
                ))
            }
            {
                routes.access.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <AccessRoute Component={Component} />
                        }
                    />
                ))
            }
            {
                routes.auth.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={<AuthRoute Component={Component} />}
                    />
                ))
            }
            {
                routes.non_auth.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={<NonAuthRoute Component={Component} />}
                    />
                ))
            }
            {
                routes.non_accessed.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={<NonAccessRoute Component={Component} />}
                    />
                ))
            }
            {
                routes.public.map(({ path, Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={<PublicRoute Component={Component} />}
                    />
                ))
            }
            {/* <Route
                key={"/sas"}
                path={"/sas"}
                element={<PublicRoute Component={OrderCart} />}
            /> */}



        </Routes>
    );
};

export default AppRoutes;